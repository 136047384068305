import { useCallback, useState } from "react";
import store from "../store";
import { setData } from "../store/appStoreSlice";

import Config from '../config';
import { toast } from 'react-toastify';
import { BigNumber } from './helper';

const Web3 = require('web3')

export const numberWithCommas = (value)=> value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const setStore = (payload) => {
  store.dispatch(setData(payload));
};

export const getStore = () => {
  return store.getState().appStore;
};


export const setReady = (inProcess) => {
  store.dispatch(setData({inProcess}));
}


export const setSearch = (search) => {
  setStore({ search });
}

export const updateState = (key) => {
  return (value) => {
    setStore({ [key]: value });
  };
}

export const minimize = (address) => {
    if (address) {
      return address.substring(0,6)+'...'+address.substring(address.length-4,address.length)
    } else {
      return "";
    }
}

export const connect = async () => {
  if (window.ethereum) {
    // use the injected Ethereum provider to initialize Web3.js
    setStore({web3: new Web3(window.ethereum)});

    // check if Ethereum provider comes from MetaMask
    if (window.ethereum.isMetaMask) {
      toast("Connected with MetaMask.");
    } else {
      toast("Non-MetaMask provider detected.");
    }
    
    
    window.ethereum.on('accountsChanged', function (accounts) {
      requestAccounts();
    })

    window.ethereum.on('networkChanged', function (networkId) {
      setStore({chainid: Number(networkId)});
    })
  } else {
    // no Ethereum provider - instruct user to install MetaMask
    toast("Please install MetaMask");

  }
}
export const getChainId = async () => {
  const { web3 } =  getStore();
  if (web3 === null) {
    return;
  }
  // get chain ID and populate placeholder
  let chainid = await web3.eth.getChainId()
  setStore({chainid: Number(chainid)});
}

export const requestAccounts = async () => {
  const { web3 } =  getStore();
  if (web3 === null) {
    return;
  }
  // request accounts from MetaMask
  await window.ethereum.request({ method: "eth_requestAccounts" });

  // get list of accounts
  const allAccounts = await web3.eth.getAccounts();
  setStore({
    defaultAccount:allAccounts[0],
    isConnected:true
  });
  
}

export const checkId = () => {
  const { chainid } =  getStore();
  return Config.allovedId.includes(chainid);
}

export const getChainConfig = () => {
  const { chainid } =  getStore();
  if (Config.allovedId.includes(chainid)) {
    return Config[Config.idtoNet[chainid]]
  } else {
    toast.error("Wrong network selected.");
    return null
  }
}

export const checkBalances = async () => {
  setReady(true);
  const chainConfig = getChainConfig();
  if (chainConfig) {
    try {
      const { web3, defaultAccount, emptyInfo } =  getStore();
      const stakecontract = new web3.eth.Contract(Config.STAKEABI, chainConfig.stake_addr)
      const erc20Contract = new web3.eth.Contract(Config.ERCABI, chainConfig.token_addr)

      const trxbalance = await erc20Contract.methods.balanceOf(defaultAccount).call({ from: defaultAccount })
      const trxonpool = await erc20Contract.methods.balanceOf(chainConfig.stake_addr).call({ from: defaultAccount })
      const tvl = await stakecontract.methods.totalSupply().call({ from: defaultAccount })
      const stakedbalance = await stakecontract.methods.balanceOf(defaultAccount).call({ from: defaultAccount })
      const withdrawable = await stakecontract.methods.withdrawable(defaultAccount).call({ from: defaultAccount })
      const ready = await stakecontract.methods.ready(defaultAccount).call({ from: defaultAccount })
      const price = await stakecontract.methods.getPrice().call({ from: defaultAccount })
      const fee = await stakecontract.methods.getFee().call({ from: defaultAccount })
      const getTotalOnTron = await stakecontract.methods.getTotalOnTron().call({ from: defaultAccount })
      const getTotalUnstaked = await stakecontract.methods.getTotalUnstaked().call({ from: defaultAccount })
      var apy = 0;
      if (parseFloat(tvl)>0) {
        apy = 100*(parseFloat(getTotalOnTron)+parseFloat(trxonpool)-parseFloat(getTotalUnstaked)-parseFloat(tvl))/parseFloat(tvl);
      }
//      var price = 1
//      if (parseFloat(getTotalOnTron)>0) {
//        price = (parseFloat(getTotalOnTron) + parseFloat(trxonpool) - parseFloat(getTotalUnstaked)) / (parseFloat(tvl))
//      }
      
      setStore({accountInfo: {
          balance:parseFloat(trxbalance)/10**6,
          staked:parseFloat(stakedbalance)/10**6,
          totalOnTron:parseFloat(getTotalOnTron)/10**6,
          totalUnstaked:parseFloat(getTotalUnstaked)/10**6,
          trxonpool:parseFloat(trxonpool)/10**6,
          price:parseFloat(price)/10**6,
          tvl:parseFloat(tvl)/10**6,
          fee:parseFloat(fee),
          apy:apy,
          withdrawable:parseFloat(withdrawable)/10**6,
          ready:parseFloat(ready)/10**6,
      }});
      
    } catch(error) {
      if(error.message) {
        toast.error(error.message)
        console.log(error.message)
      }
    }
  } else {
      const { emptyInfo } =  getStore();
      setStore({accountInfo: emptyInfo});
  }
  setReady(false);
}

const checkApproval = async (token, amount, contract) => {
  try {
    const { web3, defaultAccount } =  getStore();

    const erc20Contract = new web3.eth.Contract(Config.ERCABI, token)
    const allowance = await erc20Contract.methods.allowance(defaultAccount, contract).call({ from: defaultAccount })
    const supply = await erc20Contract.methods.totalSupply().call({ from: defaultAccount })

    const ethAllowance = web3.utils.fromWei(allowance, "ether")

    if(parseFloat(ethAllowance) < parseFloat(amount)) {
      await erc20Contract.methods.approve(contract, supply).send({ from: defaultAccount })
      toast("Approving ...");
    } 
  } catch(error) {
    if(error.message) {
      toast.error(error.message)
    }
  }
}


export const stakeBSCTRX = async (amount) => {
  setReady(true);
  const chainConfig = getChainConfig();
  if (chainConfig)
    try {
      const { web3, defaultAccount, accountInfo } =  getStore();
      const stakecontract = new web3.eth.Contract(Config.STAKEABI, chainConfig.stake_addr)
    
      await checkApproval(chainConfig.token_addr, amount, chainConfig.stake_addr);
      await stakecontract.methods.deposit(amount*10**6).send({ from: defaultAccount, value: accountInfo.fee })
      toast("Transaction confirmed ...");
      checkBalances();
    } catch(error) {
      if(error.message) {
        toast.error(error.message)
      }
    }
  setReady(false);
}


export const mintTokens = async () => {
  setReady(true);
  const chainConfig = getChainConfig();
  if (chainConfig)
    try {
      const { web3, defaultAccount, accountInfo } =  getStore();
      const erc20Contract = new web3.eth.Contract(Config.ERCABI, chainConfig.token_addr)
    
      await erc20Contract.methods.mint(2000*10**6).send({ from: defaultAccount })
      toast("Transaction confirmed ...");
      checkBalances();
    } catch(error) {
      if(error.message) {
        toast.error(error.message)
      }
    }
  setReady(false);
}


export const unstakeBSCTRX = async (amount) => {
  setReady(true);
  const chainConfig = getChainConfig();
  if (chainConfig)
    try {
      const { web3, defaultAccount, accountInfo } =  getStore();
      const stakecontract = new web3.eth.Contract(Config.STAKEABI, chainConfig.stake_addr)
      await stakecontract.methods.stopStaking(amount*10**6).send({ from: defaultAccount, value: accountInfo.fee })
      toast("Transaction confirmed ...");
      checkBalances();
    } catch(error) {
      if(error.message) {
        toast.error(error.message)
      }
    }
  setReady(false);
}

export const withdrawBSCTRX = async () => {
  setReady(true);
  const chainConfig = getChainConfig();
  if (chainConfig)
    try {
      const { web3, defaultAccount, accountInfo } =  getStore();
      const stakecontract = new web3.eth.Contract(Config.STAKEABI, chainConfig.stake_addr)
      await stakecontract.methods.withdraw().send({ from: defaultAccount, value: accountInfo.fee })
      toast("Transaction confirmed ...");
      checkBalances();
    } catch(error) {
      if(error.message) {
        toast.error(error.message)
      }
    }
  setReady(false);
}
export const t = (key) => {
  const { translator } =  getStore();
  
  if (translator[key]) {
    return translator[key];
  } else {
    return key
  }
}
